#root {
    height: 100%;
    white-space: pre-line;
}
html{height: 100%}

body {
    height: 100%
}

code {
    font-family: 'Josefin Sans', sans-serif;
}

a:hover {
    color: #2a4421;
}

a {
    color: #282c34;
}

/*

button {
  border-radius: 50%;
  background-color: brown;
  color: azure;
  width: 100px;
  height: 100px;
  outline: none;
  border: none;
  margin: 10px;

}
*/
.withScaling {
    color: #1D2830;
    display: inline-block;
    font-family: 'Josefin Sans', sans-serif;
}

.flex-box {
    display: flex;
    height: 100%;
    flex-direction: column;
    align-items: center;
}

audio {
    display: none;
}

.btn-grad {
    background-image: linear-gradient(to right, #5f2c82 0%, #49a09d 51%, #5f2c82 100%) !important;
}

.btn-grad {
    margin: 10px;
    padding: 15px 45px;
    text-align: center;
    text-transform: uppercase;
    transition: 0.5s !important;
    background-size: 200% auto;
    color: white;
    box-shadow: 0 0 20px #eee;
    border-radius: 10px;
    display: block;
}

.btn-grad:hover {
    background-position: right center !important; /* change the direction of the change here */
    color: #fff;
    text-decoration: none;
}
